export const GuestsTableData = {
    columns: [
        {field: 'guest', headerName: 'Guest', width: 240},
        {field: 'gender', headerName: 'Gender', width: 130},
        {field: 'age', headerName: 'Age', width: 130},
        {field: 'phoneNumber', headerName: 'Phone Number', width: 230},
        {field: 'birthday', headerName: 'Birthday', width: 130},
        {field: 'totalVisits', headerName: 'Total Visits', width: 130},
        //{field: 'seasonVisits', headerName: 'Season Visits', width: 230},
        {field: 'cancellations', headerName: 'Cancellations', width: 130},
        {field: 'tags', headerName: 'Tags', width: 330},
        {field: 'note', headerName: 'Note', width: 330},
    ],
    rows: []
};
export const ReservationsTableData = {
    columns: [
        {field: 'guestName', headerName: 'Guest', width: 230},
        {field: 'phoneNumber', headerName: 'Phone Number', width: 230},
        {field: 'amount', headerName: 'Amount($)', type: 'string', width: 130},
        {field: 'status', headerName: 'Status', type: 'string', width: 200},
        {field: 'note', headerName: 'Reservation Note', width: 330},
        {field: 'reservationTags', headerName: 'Tags', width: 330},
        {field: 'reservedItem', headerName: 'Reserved Item', type: 'string', width: 230},
        {field: 'date', headerName: 'Date', type: 'string', width: 130},
        {field: 'id', headerName: 'Reservation ID', width: 130},
        {field: 'reservationType', headerName: 'Res Type', type: 'string', width: 130},
    ],
    rows: []
};

export const OrdersTableHeaders = {
    columns: [
        {field: 'orderId', headerName: 'Order ID', width: 150},
        {field: 'seatNumber', headerName: 'Seat Number', width: 230},
        {
            field: 'items',
            headerName: 'Items',
            width: 300,
        },
        // {field: 'quantity', headerName: 'Quantity', type: 'string', width: 130},
        {field: 'price', headerName: 'Total Price', type: 'string', width: 200},
        {field: 'status', headerName: 'Status', width: 230},
        {field: 'note', headerName: 'Note', width: 330},
    ],
    rows: []
};
